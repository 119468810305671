/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    hr: "hr",
    div: "div",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(_components.strong, null, "identity gate"), " is a gate in quantum computing that, essentially, does nothing. Whatever state goes into it comes out again unchanged."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "In a circuit diagram, an identity gate is represented by a square with the letter ‘I’ in it, as shown below."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 620px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 29.11392405063291%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABJ0AAASdAHeZh94AAAAdklEQVQY062RSwrAIAxEvf8xXblwoyL+nRKLIq2tXTQwEsNLzCADgFrro66xY9mqaY6cM7z3CCEgxvjK0izWE4JTSkN0p2HOOQghoJSC1vrGdbaUcm5IBxV7wyxjTKtzziGlbFpxVOvbby3TY9baYfuz5b8+5QBXrNkI+RWNcQAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"identity-gate-symbol\"\n        title=\"identity-gate-symbol\"\n        src=\"/static/b0a91dc589157220d1572008b55f689b/2a195/identity-gate-symbol.png\"\n        srcset=\"/static/b0a91dc589157220d1572008b55f689b/c26ae/identity-gate-symbol.png 158w,\n/static/b0a91dc589157220d1572008b55f689b/6bdcf/identity-gate-symbol.png 315w,\n/static/b0a91dc589157220d1572008b55f689b/2a195/identity-gate-symbol.png 620w\"\n        sizes=\"(max-width: 620px) 100vw, 620px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "However, since it has no effect on the output, it rarely needs to be drawn. It is comparable to the ", React.createElement(_components.strong, null, "buffer gate"), " in classical computation."), "\n", React.createElement(_components.p, null, "The identity gate's matrix representation is below."), "\n", React.createElement(_components.p, null, "$$\nI \\equiv \\left[ \\begin{matrix}1 & 0 \\\\ 0 & 1 \\end{matrix} \\right]\n$$"), "\n", React.createElement(_components.p, null, "Applying this matrix to any state vector simply results in that same state vector."), "\n", React.createElement(_components.p, null, "Quantum gates are reversible, meaning that any given quantum gate, $U$, and its adjoint, $U^\\dagger$, applied one after another is equivalent to the identity gate: $U^\\dagger U = I$."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/en/stable/code/api/pennylane.Identity.html"
  }, "identity gate in PennyLane"), " is available as ", React.createElement(_components.code, null, "qml.Identity"), ". This gate is called ", React.createElement(_components.a, {
    href: "https://docs.quantum.ibm.com/api/qiskit/qiskit.circuit.library.IGate"
  }, React.createElement(_components.code, null, "IGate"), " in Qiskit"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
